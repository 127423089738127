/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  ClaimRewardRequest,
  GetRecentlyClaimedRewardVoucherResponse,
  GetRewardVoucherResponse,
  GetRewardsRequest,
  RewardClaimedResponse,
  RewardClaimedWebResponse,
  RewardDetailsResponse,
  RewardsClaimedResponse,
} from "../models"

export const claimReward = {
  url: "/v1/claimReward",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: ClaimRewardRequest
    res: RewardClaimedResponse
  },
} as const

export const getClaimedReward = {
  url: "/v1/claimedReward/{rewardId}",
  method: "GET",
  meta: {} as {
    pathParams: {
      rewardId: /* IS NOT ENUM */ string
    }
    res: RewardClaimedWebResponse
  },
} as const

export const getClaimedRewards = {
  url: "/v1/claimedRewards",
  method: "GET",
  meta: {} as {
    res: RewardsClaimedResponse
  },
} as const

export const getRecentlyClaimedRewardVoucher = {
  url: "/v1/rewardVoucher/recentlyClaimed",
  method: "GET",
  meta: {} as {
    res: GetRecentlyClaimedRewardVoucherResponse
  },
} as const

export const getRewardDetailsByCountry = {
  url: "/v1/fetchRewards",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: GetRewardsRequest
    res: RewardDetailsResponse
  },
} as const

export const getRewardVoucher = {
  url: "/v1/rewardVoucher/{rewardId}",
  method: "GET",
  meta: {} as {
    pathParams: {
      rewardId: /* IS NOT ENUM */ string
    }
    res: GetRewardVoucherResponse
  },
} as const
