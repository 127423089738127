import { HStack, Stack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { CopyIcon, GiftIcon, GrabLogoIcon } from "@pomebile/design-system"
import { backgroundColors, radii } from "@pomebile/primitives/tokens"
import { toast } from "react-toastify"
import { segmentAnalyticsLogger } from "../utils/segment"
import { RewardGroup } from "@pomebile/pomelo-service-api"
import { toCamelCase } from "../utils/string"

export interface ClaimRewardProps {
  rewardUniqueId: string
  senderName: string
  rewardType: RewardGroup
  description: string
  voucherCode: string
  expirationDate: Date
}

const GrabCard = () => {
  return (
    <div
      style={{
        width: "100%",
        aspectRatio: 345 / 204,
      }}
    >
      <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 345 204">
        <defs>
          <linearGradient
            id="paint0_linear_5753_19927"
            x1="301.5"
            y1="0"
            x2="33.2102"
            y2="198.888"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#00B14F" stopOpacity="0.9" />
            <stop offset="0.275" stopColor="#00B14F" />
            <stop offset="0.45" stopColor="#00B14F" stopOpacity="0.8" />
            <stop offset="0.64" stopColor="#00B14F" stopOpacity="0.9" />
            <stop offset="0.91" stopColor="#00B14F" />
          </linearGradient>
        </defs>
        <g>
          <rect
            x={0}
            y={0}
            width={345}
            height={204}
            rx={12}
            fill="url(#paint0_linear_5753_19927)"
          />
        </g>
      </svg>
    </div>
  )
}

export function ClaimReward({
  rewardUniqueId,
  senderName,
  rewardType,
  description,
  voucherCode,
  expirationDate,
}: ClaimRewardProps) {
  const handleCopyVoucherCode = () => {
    segmentAnalyticsLogger.logEvent("Copy promo code clicked", { rewardUniqueId })
    toast.success("Promo code copied to clipboard")
    navigator.clipboard.writeText(voucherCode)
  }

  const cardRewardType = toCamelCase(rewardType)

  let voucherAction

  switch (rewardType) {
    case "GRAB_BIKE":
      voucherAction = "Select your GrabBike ride"
      break
    case "GRAB_EXPRESS":
      voucherAction = "Start your GrabExpress order"
      break
    case "GRAB_FOOD":
      voucherAction = "Start your GrabFood order"
      break
    case "GRAB_MART":
      voucherAction = "Start your GrabMart order"
      break
    case "GRAB_RIDE":
      voucherAction = "Select your GrabCar ride"
      break
  }

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between" gap="xl4">
        <VStack gap="xs">
          <Txt variant="headline2">{senderName} sent you a gift!</Txt>
          <VStack gap="md">
            <Txt>Here's how to redeem your promo code:</Txt>
            <VStack>
              <ol style={{ listStylePosition: "inside" }}>
                <li>
                  <Txt>Copy the code below</Txt>
                </li>
                <li>
                  <Txt>Open your Grap app</Txt>
                </li>
                <li>
                  <Txt>{voucherAction}</Txt>
                </li>
                <li>
                  <Txt>Apply the promo code at checkout</Txt>
                </li>
              </ol>
            </VStack>
          </VStack>
        </VStack>
        <div
          style={{
            position: "relative",
            backgroundColor: backgroundColors["background-bordered-callout-default"],
            borderRadius: radii["md"],
          }}
        >
          <GrabCard />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 26,
            }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <GiftIcon width={32} height={32} fill="icon-inverse" />
            </Stack>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: 26,
            }}
          >
            <Stack direction="row">
              <GrabLogoIcon />
            </Stack>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 80,
              left: 0,
              padding: 26,
            }}
          >
            <Stack>
              <Txt variant="headline3" color="text-inverse">
                {cardRewardType}
              </Txt>
              <Txt color="text-inverse">{description}</Txt>
            </Stack>
          </div>
          <VStack padding={{ y: "sm", x: "xl2" }}>
            <HStack justifyContent="space-between" width="full" alignItems="center">
              <VStack flex={1}>
                <Txt variant="body2">Your promo code</Txt>
                <Txt variant="subtitle2">{voucherCode}</Txt>
              </VStack>
              <Stack width="fitContent">
                <div onClick={handleCopyVoucherCode}>
                  <HStack alignItems="center" gap="xs2">
                    <CopyIcon width={24} height={24} />
                    <Txt variant="button2" color="text-caption">
                      Copy
                    </Txt>
                  </HStack>
                </div>
              </Stack>
            </HStack>
          </VStack>
        </div>
        <VStack>
          <Txt variant="body2" color="text-caption">
            Valid until{" "}
            {expirationDate.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Txt>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
